import Request from ".";
import RequestWp from "./requestWp";
import { Routes } from "../constants/api_url";
import { saveAs } from "file-saver";

const OtpRequest = async (formdata) => {
  try {
    const res = await Request.post(Routes.OTP, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const verificationOfOtp = async (formdata) => {
  try {
    const res = await Request.post(Routes.VERIFYOTP, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const setNewPassword = async (formdata) => {
  try {
    const res = await Request.post(Routes.NEWPASSWORD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const changePassword = async (formdata) => {
  try {
    const res = await Request.post(Routes.CHANGEPASSWORD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateUserData = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEUSERDATA, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertAttribute = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTATTRIBUTE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAttributes = async (type, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETATTRIBUTES + "/" + type + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateAttrStatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.CHANGEATTRSTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateAttribute = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEATTRIBUTE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
const insertBranch = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTBRANCH, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getBranch = async () => {
  try {
    const res = await Request.get(Routes.GETBRANCH, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateBranch = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEBRANCH, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteBranch = async (branch_id) => {
  try {
    const res = await Request.get(Routes.DELETEBRANCH + "/" + branch_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getTypeCountry = async (type, country) => {
  try {
    const result = await Request.get(
      Routes.TYPECOUNTRY + "/" + type + "/" + country
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertService = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTSERVICE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getServicePrice = async (page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETSERVICEPRICE + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteService = async (s_id) => {
  try {
    const res = await Request.get(Routes.DELETESERVICE + "/" + s_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertFeature = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTFEATURE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getFeature = async () => {
  try {
    const result = await Request.get(Routes.GETFEATURE);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateFeature = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEFEATURE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteFeature = async (f_id) => {
  try {
    const res = await Request.get(Routes.DELETEFEATURE + "/" + f_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getLeadSource = async () => {
  try {
    const result = await Request.get(Routes.GETLEADSOURCE);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertLead = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTLEAD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllLead = async (u_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLLEAD + "/" + u_id + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteLead = async (f_id) => {
  try {
    const res = await Request.get(Routes.DELETELEAD + "/" + f_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const generateAutoProforma = async (formdata) => {
  try {
    const res = await Request.post(Routes.GENERATEAUTOPROFORMA, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};


const updateLead = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATELEAD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllBranch = async () => {
  try {
    const result = await Request.get(Routes.GETALLBRANCH);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getUsers = async (branch_id, u_id) => {
  try {
    const result = await Request.get(
      Routes.GETUSERS + "/" + branch_id + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllSoloBranch = async () => {
  try {
    const result = await Request.get(Routes.GETALLSOLOBRANCHLIST);
    return result?.data?.data;
  } catch (error) {
    throw error;
  }
};
const checkNetwork = async (ip) => {
  try {
    const result = await Request.get(Routes.CHECKNETWORK + "/" + ip);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getLeadStatus = async () => {
  try {
    const result = await Request.get(Routes.GETALLLEADSTATUS);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateLeadBranch = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATELEADBRANCH, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateLeadStatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATELEADSTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateLeadPriority = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATELEADPRIORITY, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertLeadFollowUp = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTFOLLOWUP, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getFollowUp = async (data) => {
  try {
    const res = await Request.post(Routes.GETFOLLOWUP, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateSetting = async (data) => {
  try {
    const res = await Request.post(Routes.UPDATESETTING, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getSetting = async () => {
  try {
    const res = await Request.get(Routes.GETSETTINGDATA);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertsmstemplate = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTSMSTEMPLATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllSMSTemlate = async () => {
  try {
    const result = await Request.get(Routes.GETALLSMSTEMLATE);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteSMSTemlate = async (e_t_id) => {
  try {
    const res = await Request.get(Routes.DELETESMSTEMPLATE + "/" + e_t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateSMSTemplate = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATESMSTEMLATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateAllocated = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEALLOCATED, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//sakshi
const insertRole = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTROLE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateRole = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEROLE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllRoleList = async () => {
  try {
    const result = await Request.get(Routes.GETALLROLELIST);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getRolePermission = async (r_id) => {
  try {
    const res = await Request.get(Routes.GETROLEPERMISSION + "/" + r_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertUser = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTUSER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getuserbyparentuser = async (u_id, branch_id) => {
  try {
    const result = await Request.get(
      Routes.GETALLUSERBYPARENT + "/" + u_id + "/" + branch_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getUserPermission = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETUSERPERMISSION + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateUser = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEUSER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (u_id) => {
  try {
    const res = await Request.get(Routes.DELETEUSER + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateTemplateStatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.CHANGETEMPLATESTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getusersBytype = async (type) => {
  try {
    const res = await Request.get(Routes.GETUSERBYTYPE + "/" + type, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });

    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertManagerAllocation = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTMANAGERALLOCATION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getmanagerallocationlist = async () => {
  try {
    const result = await Request.get(Routes.GETALLOCATIONLIST);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getbranchallocatedlist = async (manager_id) => {
  try {
    const res = await Request.get(
      Routes.GETBRANCHALLOCATIONLIST + "/" + manager_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateManagerAllocation = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEMANAGERALLOCATION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteManagerAllocation = async (manager_id) => {
  try {
    const res = await Request.get(
      Routes.DELETEMANAGERALLOCATION + "/" + manager_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteBranchallocatebyid = async (manager_id, branch_id) => {
  try {
    const res = await Request.get(
      Routes.DELETEALLOCATEBRANCHBYID + "/" + manager_id + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getTemplateCategory = async (id) => {
  try {
    const result = await Request.get(Routes.GETTEMPLATECATEGORY + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getTemplatesubCategory = async (cat_id) => {
  try {
    const res = await Request.get(
      Routes.GETTEMPLATESUBCATEGORY + "/" + cat_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertemailtemplate = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTEMAILTEMPLATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getEmailTemplate = async () => {
  try {
    const result = await Request.get(Routes.GETEMAILTEMPLATE);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateEmailTemplate = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEEMAILTEMPLATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteEmailTemplate = async (e_t_id) => {
  try {
    const res = await Request.get(Routes.DELETEEMAILTEMPLATE + "/" + e_t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getBranchData = async (branch_id) => {
  try {
    const res = await Request.get(Routes.GETBRANCHDATA + "/" + branch_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateBranchSetting = async (data) => {
  try {
    const res = await Request.post(Routes.UPDATEBRANCHSETTING, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteEmailImg = async (e_a_id) => {
  try {
    const res = await Request.get(Routes.DELETEEMAILIMG + "/" + e_a_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchLeadData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHLEAD, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Task Start
const insertTask = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTTASK, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllTask = async (u_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLTASK + "/" + u_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteTask = async (t_id) => {
  try {
    const res = await Request.get(Routes.DELETETASK + "/" + t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteTaskImg = async (t_a_id) => {
  try {
    const res = await Request.get(Routes.DELETETASKIMG + "/" + t_a_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateTask = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATETASK, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateTaskPriority = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATETASKPRIORITY, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateTaskStatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATETASKSTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertTaskFeedback = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTTASKFEEDBACK, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getFeedback = async (t_id) => {
  try {
    const res = await Request.get(Routes.GETFEEDBACK + "/" + t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updatetaskAllocated = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATETASKALLOCATED, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertConfirmation = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTCONFIRMATION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
//Task End

const gettodayleadfollowup = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETTODAYLEADREMINDER + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//tasknotification

const getnewTask = async (data) => {
  try {
    const res = await Request.post(Routes.GETNEWTASK, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//readTaskNotification
const readTaskNotification = async (t_id) => {
  try {
    const res = await Request.get(Routes.READTASKNOTIFICATION + "/" + t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getTaskById = async (t_id) => {
  try {
    const result = await Request.get(Routes.GETTASKBYID + "/" + t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    let res = {};
    res = { ...result?.data?.data, task_image: result?.data?.task_image };
    return res;
  } catch (error) {
    throw error;
  }
};

const getcountAllTask = async (u_id, t_status) => {
  try {
    const result = await Request.get(
      Routes.GETCOUNTALLTASK + "/" + u_id + "/" + t_status,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getcountAllLead = async (u_id, l_service) => {
  try {
    const result = await Request.get(
      Routes.GETCOUNTALLLEAD + "/" + u_id + "/" + l_service,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getcountAllTodayLead = async (u_id, l_service) => {
  try {
    const result = await Request.get(
      Routes.GETCOUNTALLTODAYLEAD + "/" + u_id + "/" + l_service,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

//customer
const checkCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.CHECKCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerList = async (page, perPage, uid, bid) => {
  try {
    const res = await Request.get(
      Routes.GETCUSTOMERLIST +
        "/" +
        page +
        "/" +
        perPage +
        "/" +
        uid +
        "/" +
        bid,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getUser = async () => {
  try {
    const result = await Request.get(Routes.GETUSER);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const searchCustomerList = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHCUSTOMERLIST, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAttributesbyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETATTRIBUTESBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getPayment = async (id) => {
  try {
    const res = await Request.get(Routes.GETPAYMENT + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updatePayment = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEPAYMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
const updatePaymentWithAdditionalCharges = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.UPDATEPAYMENTWITHADDITIONALCHARGES,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updatePaymentWithTdsAmount = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.UPDATEPAYMENTWITHTDSAMOUNT,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerbyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETCUSTOMERBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATECUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerAmount = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCUSTOMERAMOUNT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteCustomer = async (id) => {
  try {
    const res = await Request.get(Routes.DELETECUSTOMER + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Visa And Attestation Start
const getAllVisaList = async (u_id, branch_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLVISALIST +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        page +
        "/" +
        perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getVisabyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETVISABYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateVisaStatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEVISASTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteVisa = async (v_id) => {
  try {
    const res = await Request.get(Routes.DELETEVISA + "/" + v_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateVisa = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEVISA, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllAttestationList = async (u_id, branch_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLATTESTATIONLIST +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        page +
        "/" +
        perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateattestationstatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEATTESTATIONSTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteAttestation = async (
  a_id,
  state_id,
  c_gst_pre,
  s_gst_pre,
  i_gst_pre
) => {
  try {
    const res = await Request.get(
      Routes.DELETEATTESTATION +
        "/" +
        a_id +
        "/" +
        state_id +
        "/" +
        c_gst_pre +
        "/" +
        s_gst_pre +
        "/" +
        i_gst_pre,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateAttestation = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEATTESTATION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchVisaData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHLVISA, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchAttestationData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHATTESTATION, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
//Visa And Attestation End

//apostille
const getAllApostilleList = async (u_id, branch_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLAPOSTILLELIST +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        page +
        "/" +
        perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateapostillestatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEAPOSTILLESTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteApostille = async (
  a_id,
  state_id,
  c_gst_pre,
  s_gst_pre,
  i_gst_pre
) => {
  try {
    const res = await Request.get(
      Routes.DELETEAPOSTILLE +
        "/" +
        a_id +
        "/" +
        state_id +
        "/" +
        c_gst_pre +
        "/" +
        s_gst_pre +
        "/" +
        i_gst_pre,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateApostille = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEAPOSTILLE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchApostilleData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHAPOSTILLE, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getApostillebyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETAPOSTILLEBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
//apostille end

const insertCustomerConversation = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.INSERTCUSTOMERCONVERSATION,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getcomment = async (c_id) => {
  try {
    const res = await Request.get(Routes.GETCOMMENT + "/" + c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const uploadCustomerFile = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPLOADCUSTOMERFILE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllFiles = async (c_id) => {
  try {
    const result = await Request.get(Routes.GETALLFILESBYID + "/" + c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteFile = async (c_f_id) => {
  try {
    const res = await Request.get(Routes.DELETEFILE + "/" + c_f_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getRefundAmt = async (c_id) => {
  try {
    const result = await Request.get(Routes.GETREFUNDAMT + "/" + c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

//Payment
const getPaymentList = async (page, perPage, u_id, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETPAYMENTLIST +
        "/" +
        page +
        "/" +
        perPage +
        "/" +
        u_id +
        "/" +
        branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchPaymentData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHPAYMENT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertrefund = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTREFUND, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getPaymentDetails = async (c_id) => {
  try {
    const result = await Request.get(Routes.GETPAYMENTDETAILS + "/" + c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllRefunddata = async (u_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLREFUNDDATA + "/" + u_id + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchRefundData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHREFUND, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsettingdata = async (u_id) => {
  try {
    const result = await Request.get(Routes.GETSETTINGDATABYID + "/" + u_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

//cashflow

const getBranchUser = async (id) => {
  try {
    const res = await Request.get(Routes.GETBRANCHUSER + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
const getCashPayment = async (u_id, b_id) => {
  try {
    const res = await Request.get(
      Routes.GETCASHPAYMENT + "/" + u_id + "/" + b_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertCashflow = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTCASHFLOW, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getSearchCashFlow = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHCASHFLOW, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteCashflow = async (id) => {
  try {
    const res = await Request.get(Routes.DELETECASHFLOW + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateApplicationStatus = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEAPPLICATIONSTATUS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllUnderProcesslist = async (u_id, branch_id, user_type) => {
  try {
    const result = await Request.get(
      Routes.GETALLUNDERPROCESSLIST +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        user_type
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchTaskData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHTASKDATA, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//TDS
const getTDSList = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETTDSLIST, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateTDS = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATETDS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAttestationbyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETATTESTATIONBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Fetch Invoice Data
const fetchInvoiceDatabycid = async (cid, uid) => {
  try {
    const res = await Request.get(
      Routes.GETINVOICEDATA + "/" + cid + "/" + uid,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Update Address & download Pdf
const updateCustomerAddress = async (formdata, status_id,status=false) => {
  try {
    const res = await Request.post(Routes.UPDATECADDRESS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      if(!status){
        Request.get("/fetch-pdf/" + ino, { responseType: "blob" }).then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, "invoice_" + ino + ".pdf");
  
          if (status_id == 0) {
            saveAs(pdfBlob, "invoice_" + ino + ".pdf");
          } else if (status_id == 1) {
            const fileurl = URL.createObjectURL(pdfBlob);
            const pdfWindow = window.open(); // Open a new tab
            pdfWindow.location.href = fileurl; // Load the PDF into the new tab
          } else {
            // console.log(data, "data");
          }
        });
      }
      return data?.data;
    });
    return res;
  } catch (error) {
    throw error;
  }
};

//Send Invoice AS Mail
const SendInvoiceMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDINVOICEMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getbranchdatabyid = async (bid) => {
  try {
    const res = await Request.get(Routes.FETCHBRANCHDATABYID + "/" + bid, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//coverletter
const getBusinessSummary = async (page, perPage, uid, bid) => {
  try {
    const res = await Request.get(
      Routes.GETBUSINESSSUMMARY +
        "/" +
        page +
        "/" +
        perPage +
        "/" +
        uid +
        "/" +
        bid,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchBusinessSummary = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHBUSINNESSSUMMARY, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchAttestationcover = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHATTESTATIONCOVER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//apostillecover
const searchApostillecover = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHAPOSTILLECOVER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Atestationpdf
const attestationPDF = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.ATTESTATIONPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-attestation-pdf/" + ino, {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // saveAs(pdfBlob, "attestation_" + ino + ".pdf");
        if (status_id == 0) {
          saveAs(pdfBlob, "attestation_" + ino + ".pdf");
        } else {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
      });
      return data;
    });
    return res?.data?.result;
  } catch (error) {
    throw error;
  }
};
//Apostillepdf
const apostillePDF = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.APOSTILLEPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-apostille-pdf/" + ino, { responseType: "blob" }).then(
        (res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // saveAs(pdfBlob, "apostille_" + ino + ".pdf");
          if (status_id == 0) {
            saveAs(pdfBlob, "apostille_" + ino + ".pdf");
          } else {
            const fileurl = URL.createObjectURL(pdfBlob);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileurl;
            // window.open(pdfBlob, "_blank");
          }
        }
      );
      return data;
    });
    return res?.data?.result;
  } catch (error) {
    throw error;
  }
};

//apostillecover
const searchVisacover = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHVISACOVER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Visapdf
const visaPDF = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.VISAPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-visa-pdf/" + ino, { responseType: "blob" }).then(
        (res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // saveAs(pdfBlob, "visa_" + ino + ".pdf");
          if (status_id == 0) {
            saveAs(pdfBlob, "visa_" + ino + ".pdf");
          } else {
            const fileurl = URL.createObjectURL(pdfBlob);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileurl;
            // window.open(pdfBlob, "_blank");
          }
        }
      );
      return data;
    });
    return res?.data?.result;
  } catch (error) {
    throw error;
  }
};
//Send coverletter AS Mail
const SendAttestationMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDATTESTATIONMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
const SendApostilleMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDAPOSTILLEMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
const SendVisaMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDVISAMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//dispatch
const searchDispatch = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHDISPATCH, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const dispatchPDF = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.DISPATCHPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-dispatch-pdf/" + ino, { responseType: "blob" }).then(
        (res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // saveAs(pdfBlob, "dispatchreports_" + ino + ".pdf");
          if (status_id == 0) {
            saveAs(pdfBlob, "dispatchreports_" + ino + ".pdf");
          } else {
            const fileurl = URL.createObjectURL(pdfBlob);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileurl;
            // window.open(pdfBlob, "_blank");
          }
        }
      );
    });
  } catch (error) {
    throw error;
  }
};

//excelcover
const searchExcelcover = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHEXCELCOVER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//clientaccount
const getBranchCustomer = async (bid, uid, isSoloBranch) => {
  try {
    const res = await Request.get(
      Routes.GETBRANCHCUSTOMER + "/" + bid + "/" + uid + "/" + isSoloBranch,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(res, "resresresres");
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchAccountcover = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHACCOUNTREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const clientAccountPDF = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.CLIENTACCOUNTPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-accountreport-pdf/" + ino, {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // saveAs(pdfBlob, "clientaccountreports_" + ino + ".pdf");
        if (status_id == 0) {
          saveAs(pdfBlob, "clientaccountreports_" + ino + ".pdf");
        } else {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
      });
    });
  } catch (error) {
    throw error;
  }
};

const getRefundbyid = async (id, uid) => {
  try {
    const res = await Request.get(Routes.GETREFUNDBYID + "/" + id + "/" + uid, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getRefundReceipt = async (id, receipt_no, uid, status_id) => {
  try {
    const res = await Request.get(
      Routes.GETREFUNDRECEIPT + "/" + id + "/" + receipt_no + "/" + uid,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    ).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-refundreceipt/" + ino, { responseType: "blob" }).then(
        (res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // saveAs(pdfBlob, "refund_receipt_" + ino + ".pdf");
          if (status_id == 0) {
            saveAs(pdfBlob, "refund_receipt_" + ino + ".pdf");
          } else {
            const fileurl = URL.createObjectURL(pdfBlob);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileurl;
            // window.open(pdfBlob, "_blank");
          }
        }
      );
    });
  } catch (error) {
    throw error;
  }
};

const getPaymentDatabyid = async (id, bid, uid) => {
  //08-07
  try {
    const res = await Request.get(
      Routes.GETPAYMENTDATABYID + "/" + id + "/" + bid + "/" + uid,
      {
        //08-07
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getPaymentReceipt = async (id, receipt_no, uid, bid, status_id) => {
  //08-07
  try {
    const res = await Request.get(
      Routes.GETPAYMENTRECEIPT +
        "/" +
        id +
        "/" +
        receipt_no +
        "/" +
        uid +
        "/" +
        bid,
      {
        //08-07
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    ).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-paymentreceipt/" + ino, {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // saveAs(pdfBlob, "payment_receipt_" + ino + ".pdf");
        if (status_id == 0) {
          saveAs(pdfBlob, "payment_receipt_" + ino + ".pdf");
        } else if (status_id == 1) {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
      });
    });
  } catch (error) {
    throw error;
  }
};

const fetchgraphleaddata = async (uid) => {
  try {
    const res = await Request.get(Routes.FETCHGRAPHLEADDATA + "/" + uid, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const generateCRegistrationReport = async (data) => {
  try {
    const res = await Request.post(Routes.GENERATECREGISTRATIONREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const generateRDeliveryReport = async (data) => {
  try {
    const res = await Request.post(Routes.GENERATERDELIVERYREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const generateAllRDeliveryReport = async (data) => {
  try {
    const res = await Request.post(Routes.GENERATEALLRDELIVERYREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const generateRDeliveryHistoryReport = async (data) => {
  try {
    const res = await Request.post(
      Routes.GENERATERDELIVERYHISTORYREPORT,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const generateAllRDeliveryHistoryReport = async (data) => {
  try {
    const res = await Request.post(
      Routes.GENERATEALLRDELIVERYHISTORYREPORT,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Send Refund AS Mail
const SendRefundMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDREFUNDMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Send Payment AS Mail
const SendPaymentMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDPAYMENTMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchLeadReport = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHLEADREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchLeadCountReport = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHLEADCOUNTREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchDirectCustomerReport = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHCUSTOMERCOUNTREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchLeadAllocateReport = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHLEADALLOCATEREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const convertLead = async (lead_id, u_id, branch_id) => {
  try {
    const res = await Request.get(
      Routes.CONVERTLEAD + "/" + lead_id + "/" + u_id + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getcountFollowUp = async (data) => {
  try {
    const res = await Request.post(Routes.GETCOUNTFOLLOWUP, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchLeadFollowupReport = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHLEADFOLLOWUPREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//nsertFullcopy

const insertFullcopy = async (id, branch_id, user_id) => {
  try {
    const res = await Request.get(
      Routes.INSERTFULLCOPY + "/" + id + "/" + branch_id + "/" + user_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

//halfcopy
const insertHalfcopy = async (id) => {
  try {
    const res = await Request.get(Routes.INSERTHALFCOPY + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

//profitreport
const searchProfitReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHPROFITREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//download all pdf report
const downloadallpdfreport = async (fdata) => {
  try {
    const res = await Request.post(Routes.DOWNLOADALLPDF, fdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-pdf/" + ino, { responseType: "blob" }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "invoice_" + ino + ".pdf");
      });
    });
  } catch (error) {
    throw error;
  }
};

const insertLeadCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTLEADCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Fetch Invoice Data
const fetchProformaInvoiceDatabylid = async (lid, uid) => {
  try {
    const res = await Request.get(
      Routes.GETPROFORMAINVOICEDATA + "/" + lid + "/" + uid,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//download proforma invoice pdf
const getProformaInvoice = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.GETPROFORMAINVOICE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-proformainvoicepdf/" + ino, {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // saveAs(pdfBlob, "invoice_" + ino + ".pdf");
        if (status_id == 0) {
          saveAs(pdfBlob, "invoice_" + ino + ".pdf");
        } else if (status_id == 1) {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
      });
    });
  } catch (error) {
    throw error;
  }
};

//For Email Template Purpose
const getSelectionwiseAmt = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETSELECTIONWISEAMT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Update Service
const updateService = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATESERVICE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateDispatchDate = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEDISPATCHDATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllEmailTemplate = async () => {
  try {
    const result = await Request.get(Routes.GETALLEMAILTEMLATE);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

//Send Lead Mail
const sendLeadMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDLEADMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

////////////////////////////// customer ////////////////////////////
const getCustomers = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETCUSTOMERS + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchCustomers = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHCUSTOMERS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAttestations = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETATTESTATIONS + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchAttestations = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHATTESTATIONS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getApostilles = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETAPOSTILLES + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchApostilles = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHAPOSTILLES, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//translationcust
const getTranslations = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETTRANSLATIONS + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchTranslations = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHTRANSLATIONS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getVisas = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETVISAS + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchVisas = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHVISAS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getPayments = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETPAYMENTS + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchPayments = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHPAYMENTS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getcustomerTDSList = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETCUSTOMERTDSLIST + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchTDSData = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHTDSDATA, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getApplicants = async (c_id, c_s_type) => {
  try {
    const res = await Request.get(
      Routes.GETAPPLICANTS + "/" + c_id + "/" + c_s_type,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertCustomerFeedback = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTCUSTOMERFEEDBACK, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerFeedbacks = async (m_c_id, page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETCUSTOMERFEEDBACK + "/" + m_c_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getallInvoice = async (c_id) => {
  try {
    const res = await Request.get(Routes.GETALLINVOICE + "/" + c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertgoal = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTGOAL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllGoal = async () => {
  try {
    const result = await Request.get(Routes.GETALLGOAL);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateGoal = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEGOAL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteGoal = async (e_t_id) => {
  try {
    const res = await Request.get(Routes.DELETEGOAL + "/" + e_t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllGoallist = async (u_id) => {
  try {
    const result = await Request.get(Routes.GETALLGOALLIST + "/" + u_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllDashTask = async (u_id) => {
  try {
    const result = await Request.get(Routes.GETALLDASHTASK + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getTaskUsers = async (branch_id, u_id) => {
  try {
    const result = await Request.get(
      Routes.GETTASKUSERS + "/" + branch_id + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const branchTransfer = async (formdata) => {
  try {
    const res = await Request.post(Routes.BRANCHTRANSFER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Fetch Manager Branch
const fetchMangerwiseBranch = async (uid) => {
  try {
    const res = await Request.get(Routes.FETCHMANAGERBRANCH + "/" + uid, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Fetch Manager Branch
const fetchUserByBranch = async (bid) => {
  try {
    const res = await Request.get(Routes.FETCHBRANCHUSERDATA + "/" + bid, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//get revenue data
const getrevenuedata = async (data) => {
  try {
    const res = await Request.post(Routes.GETREVENUEDATA, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getactivitylogbyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETACTIVITYLOGBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getLoginActivityData = async (page, perPage) => {
  try {
    const res = await Request.get(
      Routes.GETLOGINACTIVITYDATA + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchLoginActivityData = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHLOGINACTIVITYDATA, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const addlogouttime = async (formdata) => {
  try {
    const res = await Request.post(Routes.ADDLOGOUTTIME, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Hold status application in dashboard
const getallholdapplication = async (u_id, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETALLHOLDAPPLICATION + "/" + u_id + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllDispatchingApplications = async (u_id, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETALLDISPATCHINGAPPLICATIONS + "/" + u_id + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertFollowUp = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTHOLDCOMMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Translation
const getAllTranslationList = async (u_id, branch_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLTRANSLATIONLIST +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        page +
        "/" +
        perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getTranslationbyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETTRANSLATIONBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteTranslation = async (
  a_id,
  state_id,
  c_gst_pre,
  s_gst_pre,
  i_gst_pre
) => {
  try {
    const res = await Request.get(
      Routes.DELETETRANSLATION +
        "/" +
        a_id +
        "/" +
        state_id +
        "/" +
        c_gst_pre +
        "/" +
        s_gst_pre +
        "/" +
        i_gst_pre,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateTranslation = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATETRANSLATION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchTranslationData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHTRANSLATION, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Translation pdf
const translationPDF = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.TRANSLATIONPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-translation-pdf/" + ino, {
        responseType: "blob",
      }).then((resp) => {
        const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
        // saveAs(pdfBlob, "translation_" + ino + ".pdf");
        if (status_id == 0) {
          saveAs(pdfBlob, "translation_" + ino + ".pdf");
        } else if (status_id == 1) {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
      });
      return data;
    });
    return res?.data?.result;
  } catch (error) {
    throw error;
  }
};

const searchTranslationcover = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHTRANSLATIONCOVER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const SendTranslationMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDTRANSLATIONMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Translation End

const blockCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.BLOCKCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const SendLeadInvoiceMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDLEADINVOICEMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const SendLeadEmail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDLEADEMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const changeCustomerPassword = async (formdata) => {
  try {
    const res = await Request.post(Routes.CHANGECUSTOMERPASSWORD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const gettemplatelistbycate = async (cid, scid, type, c_tax) => {
  try {
    const res = await Request.get(
      Routes.GETTEMPLISTBYCATE +
        "/" +
        cid +
        "/" +
        scid +
        "/" +
        type +
        "/" +
        c_tax || 0,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const gettemplatedata = async (etid) => {
  try {
    const res = await Request.get(Routes.GETTEMPLATEDATA + "/" + etid, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getcourierchargereport = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCOURIERCHARGEREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Send Template Mail
const sendTempMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDTEMPMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
const getUserById = async (u_id) => {
  try {
    const result = await Request.get(Routes.GETUSERBYID + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getFaqCategory = async () => {
  try {
    const result = await Request.get(Routes.GETFAQCATEGORY, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertFaqCategory = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTFAQCATEGORY, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateFaqCategory = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEFAQCATEGORY, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertFaq = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTFAQ, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getFaq = async () => {
  try {
    const result = await Request.get(Routes.GETFAQ, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateFAQ = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEFAQ, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteFaq = async (f_id) => {
  try {
    const res = await Request.get(Routes.DELETEFAQ + "/" + f_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteFaqCategory = async (f_c_id) => {
  try {
    const res = await Request.get(Routes.DELETEFAQCATEGORY + "/" + f_c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//getProcessLimitDays
const getProcessLimitDate = async (a_type) => {
  try {
    const res = await Request.get(Routes.GETPROCESSLIMITDATE + "/" + a_type, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data?.process_limit_date;
  } catch (error) {
    throw error;
  }
};

//  TARGET
const insertTarget = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTTARGET, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getTarget = async () => {
  try {
    const result = await Request.get(Routes.GETTARGET, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateTarget = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATETARGET, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getUserTaragetReport = async (u_id) => {
  try {
    const result = await Request.get(Routes.GETUSERTARGETREPORT + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const fetchtotalinvoice = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETTOTALINVOICE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const approvedPayment = async (formdata) => {
  try {
    const res = await Request.post(Routes.APPROVEDPAYMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deletePayment = async (formdata) => {
  try {
    const res = await Request.post(Routes.DELETEPAYMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getDraftReceiptList = async (page, perPage, u_id, branch_id, pmode) => {
  try {
    const res = await Request.get(
      Routes.GETDRAFTRECEIPTLIST +
        "/" +
        page +
        "/" +
        perPage +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        pmode,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getDraftPaymentDatabyid = async (id, bid, uid) => {
  try {
    const res = await Request.get(
      Routes.GETDRAFTPAYMENTDATABYID + "/" + id + "/" + bid + "/" + uid,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getDraftPaymentReceipt = async (id, receipt_no, uid, bid) => {
  //08-07
  try {
    const res = await Request.get(
      Routes.GETDRAFTPAYMENTRECEIPT +
        "/" +
        id +
        "/" +
        receipt_no +
        "/" +
        uid +
        "/" +
        bid,
      {
        //08-07
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    ).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-draftpaymentreceipt/" + ino, {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "draft_payment_receipt_" + ino + ".pdf");
      });
    });
  } catch (error) {
    throw error;
  }
};

const getsearchDraftPaymentData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHDRAFTPAYMENT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

// customer summary report jil

const searchAccountReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHACCOUNTSUMMARY, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

const sendMailfortranslation = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDMAILFORTRANSLATION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertsuggestion = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTSUGGESTION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllSuggestion = async (u_id, user_type, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETALLSUGGESTION + "/" + u_id + "/" + user_type + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateSuggestion = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATESUGGESTION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteSuggestion = async (sg_id) => {
  try {
    const res = await Request.get(Routes.DELETESUGGESTION + "/" + sg_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getUserMonthTaragetReport = async (u_id) => {
  try {
    const result = await Request.get(
      Routes.USERMONTHTARGETREPORT + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteTarget = async (e_t_id) => {
  try {
    const res = await Request.get(Routes.DELETETARGET + "/" + e_t_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllmaincustomer = async (u_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETALLMAINCUSTOMER + "/" + u_id + "/" + page + "/" + perPage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateMainCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEMAINCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Send Customer Login Detail Mail
const sendloginMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDLOGINMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchMaincustomerData = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHMAINCUSTOMERDATA, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getEmbassyFeeList = async (page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETEMBASSYFEELIST + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertEmbassyFee = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTEMBASSYFEES, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateEmbassyFee = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEEMBASSYFEE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCourierChargeList = async (page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETCOURIERCHARGELIST + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertCourierCharge = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTCOURIERCHARGES, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateCourierCharge = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATECOURIERCHARGE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchCourierCharges = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHCOURIERCHARGES, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchChargeReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHCHARGEREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getEmbassyAmount = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETEMBASSYAMOUNT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCourierChargeAmount = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCOURIERCHARGEAMOUNT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const fetchcourierreport = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCOURIERREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const fetchAllcourierreport = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETALLCOURIERREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getallnewlead = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETALLNEWLEAD + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchAllcover = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHALLCOVER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const allcoverPDF = async (formdata, status_id) => {
  try {
    const res = await Request.post(Routes.ALLCOVERPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      Request.get("/fetch-allcover-pdf", {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        if (status_id == 0) {
          saveAs(pdfBlob, "allcover.pdf");
        } else {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
        // saveAs(pdfBlob, "allcover.pdf");
      });
      return data;
    });
    // return res?.data?.result;
  } catch (error) {
    throw error;
  }
};

const searchStatuswiseLeadReport = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHSTATUSWISELEADREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const generateCollectedReport = async (data) => {
  try {
    const res = await Request.post(Routes.GENERATECOLLECTEDREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const generateDispatchedReport = async (data) => {
  try {
    const res = await Request.post(Routes.GENERATEDISPATCHEDREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllProcessOverdue = async (u_id, branch_id, user_type) => {
  try {
    const res = await Request.get(
      Routes.GETALLPROCESSOVERDUE +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        user_type,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const setnextprocessfinishdate = async (data) => {
  try {
    const res = await Request.post(Routes.SETNEXTPROCESSFINISHDATE, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//embassyFeesreport
const searchEmbassyFeesReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHEMBASSYFEESREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllnewCustomerofAnotherBranch = async (u_id, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETALLNEWCUSTOMEROFANOTHERBRANCH + "/" + u_id + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getFAQcatepermission = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETFAQCATEPERMISSION + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getalldraftreceipt = async (u_id, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETALLDRAFTRECEIPT + "/" + u_id + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllCashFlow = async () => {
  try {
    const res = await Request.get(Routes.GETALLCASHFLOW, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const readCashFlow = async (id) => {
  try {
    const res = await Request.get(Routes.READALLCASHFLOW + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllapplicants = async (m_c_id) => {
  try {
    const res = await Request.get(Routes.GETALLAPPLICANTS + "/" + m_c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const fetchMonthInvoice = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETMONTHINVOICE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getallpickupusers = async (user_type, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETALLPICKUPUSERS + "/" + user_type + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllInPickupList = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETALLINPICKUPLIST + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertPickupnote = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTPICKUPNOTE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllOutPickupList = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETALLOUTPICKUPLIST + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const pickupOutDone = async (formdata) => {
  try {
    const res = await Request.post(Routes.PICKUPDONE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllPickupDoneList = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETALLPICKUPDONELIST + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllPickupDoneLeadList = async (u_id) => {
  try {
    const res = await Request.get(
      Routes.GETALLPICKUPDONELEADLIST + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchAllPickupDoneLeadList = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.SEARCHALLPICKUPDONELEADLIST,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertQuestion = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTQUESTION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getallquestion = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETALLQUESTION + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteQuestion = async (qid) => {
  try {
    const res = await Request.get(Routes.DELETEQUESTION + "/" + qid, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateQuestion = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEQUESTION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAllQuestionlist = async (u_id, type) => {
  try {
    const result = await Request.get(
      Routes.GETALLQUESTIONLIST + "/" + u_id + "/" + type,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertDailyQueAns = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTDAILYQUEANS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getuserchat = async (
  u_id,
  branch_id,
  user_type,
  is_marketing,
  marketing_parent_id
) => {
  try {
    const result = await Request.get(
      Routes.GETUSERSCHAT +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        user_type +
        "/" +
        is_marketing +
        "/" +
        marketing_parent_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getallcustomerchat = async (u_id, m_c_id, type, receiver_type) => {
  try {
    const result = await Request.get(
      Routes.GETCUSTOMERCHAT +
        "/" +
        u_id +
        "/" +
        m_c_id +
        "/" +
        type +
        "/" +
        receiver_type,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertusermessage = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTUSERMESSAGE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchmaincustomerchat = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHMAINCUSTOMERCHAT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const fetchchatAllcustomer = async (u_id, chat_user_type) => {
  try {
    const result = await Request.get(
      Routes.GETCHATALLCUSTOMER + "/" + u_id + "/" + chat_user_type,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerUser = async (sender_id, receiver_id) => {
  try {
    const result = await Request.get(
      Routes.GETCUSTOMERUSER + "/" + sender_id + "/" + receiver_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const gettemplatebody = async (e_t_id, u_id) => {
  try {
    const result = await Request.get(
      Routes.GETTEMPLATEBODY + "/" + e_t_id + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getEmailHistory = async (id, type) => {
  try {
    const result = await Request.get(
      Routes.GETEMAILHISTORY + "/" + id + "/" + type,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getDraftReceiptCounter = async (u_id, branch_id, pmode) => {
  try {
    const res = await Request.get(
      Routes.GETDRAFTRECEIPTCOUNTER +
        "/" +
        u_id +
        "/" +
        branch_id +
        "/" +
        pmode,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getTaskReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHTASKREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//tdsreport
// const getTDSReport = async (page, perPage, uid, bid) => {
//   try {
//     const res = await Request.get(
//       Routes.GETTDSREPORT +
//         "/" +
//         page +
//         "/" +
//         perPage +
//         "/" +
//         uid +
//         "/" +
//         bid,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           accept: "application/json",
//         },
//       }
//     );
//     return res?.data;
//   } catch (error) {
//     throw error;
//   }
// };

const searchTDSReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.SEARCHTDSREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateDraftPayment = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEDRAFTPAYMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updatePaymentMode = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEPAYMENTMODE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getservicevalue = async (country, type, stype) => {
  try {
    const res = await Request.get(
      Routes.GETSERVICEVALUE + "/" + country + "/" + type + "/" + stype,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertDirCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTDIRCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertBankDetail = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTBANKDETAILS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getBankDetails = async (user_type, branch_id) => {
  try {
    const res = await Request.get(
      Routes.GETBANKDETAILS + "/" + user_type + "/" + branch_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteBankdetails = async (branch_id) => {
  try {
    const res = await Request.get(Routes.DELETEBANKDETAILS + "/" + branch_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const draftReceiptRead = async (formdata) => {
  try {
    const res = await Request.post(Routes.DRAFTRECEIPTREAD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const branchCustomerRead = async (formdata) => {
  try {
    const res = await Request.post(Routes.BRANCHCUSTOMERREAD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const newLeadRead = async (formdata) => {
  try {
    const res = await Request.post(Routes.NEWLEADREAD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getservicevalueType = async (id, country, stype,doc_type) => {
  try {
    const res = await Request.get(
      Routes.GETSERVICEVALUETYPE + "/" + id + "/" + country + "/" + stype + "/" + doc_type,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const newPickDoneRead = async (l_id) => {
  try {
    const res = await Request.get(Routes.NEWPICKDONEREAD + "/" + l_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const leadFollowRead = async (formdata) => {
  try {
    const res = await Request.post(Routes.LEADFOLLOWREAD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchServiceprice = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHSERVICEPRICE, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getreferralcustomerlist = async (m_c_id, page, perpage) => {
  try {
    const res = await Request.get(
      Routes.GETREFERRALCUSTOMER + "/" + m_c_id + "/" + page + "/" + perpage,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getmaincustomerbyid = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETMAINCUSTOMERBYID, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCommisionData = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCOMMISIONDATA, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateCommisionPayment = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATECOMMISIONPAYMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCommisionReportData = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCOMMISIONREPORTDATA, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteCommision = async (formdata) => {
  try {
    const res = await Request.post(Routes.DELETECOMMISION, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const checkcommissiondatabyid = async (c_id) => {
  try {
    const res = await Request.get(Routes.CHECKCOMMISSIONDATABYID + "/" + c_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchEmbassyfees = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHEMBASSYFEES, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getsearchFeedback = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHFEEDBACK, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updatPaymentWithApproval = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEPAYMENTWITHAPPROVAL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getAttestationListBycid = async (c_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETATTESTATIONLISTBYCID + "/" + c_id + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getSearchCashInHand = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHCASHINHAND, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchEmbassyFeesReportbyall = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.SEARCHEMBASSYFEESREPORTALL,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getleadfollowup = async (l_id, c_id) => {
  try {
    const res = await Request.get(
      Routes.GETLEADFOLLOWUP + "/" + l_id + "/" + c_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getUserList = async (branch_id, u_id) => {
  try {
    const result = await Request.get(
      Routes.GETUSERLIST + "/" + branch_id + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getallchat = async (u_id) => {
  try {
    const res = await Request.get(Routes.GETALLCHAT + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const usercheck = async (u_id) => {
  try {
    const res = await Request.get(Routes.USERCHECK + "/" + u_id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const gettodaycustomerfollowup = async (u_id) => {
  try {
    const res = await Request.get(
      Routes.GETTODAYCUSTOMERREMINDER + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const customerFollowRead = async (formdata) => {
  try {
    const res = await Request.post(Routes.CUSTOMERFOLLOWREAD, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getcountCFollowUp = async (data) => {
  try {
    const res = await Request.post(Routes.GETCOUNTCFOLLOWUP, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchattribute = async (data) => {
  try {
    const res = await Request.post(Routes.SEARCHATTRIBUTE, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getApostilleListBycid = async (c_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETAPOSTILLELISTBYCID + "/" + c_id + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getTranslationListBycid = async (c_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETTRANSLATIONLISTBYCID + "/" + c_id + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getVisaListBycid = async (c_id, page, perPage) => {
  try {
    const result = await Request.get(
      Routes.GETVISALISTBYCID + "/" + c_id + "/" + page + "/" + perPage
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updatunderprocessdate = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEUNDERPROCESSDATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//Send Policy AS Mail
const SendPolicyMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDPOLICYMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const sendCodeMail = async (data) => {
  try {
    const res = await Request.post(Routes.SENDCODEMAIL, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getDetailId = async (data) => {
  try {
    const res = await Request.post(Routes.GETDETAILID, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const searchPickUpDoneCourierReport = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.SEARCHPICKUPDONECOURIERREPORT,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getMultiInvoiceSingleCustomerReport = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.GETMULTIINVOICESINGLECUSTOMER,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteTds = async (formdata) => {
  try {
    const res = await Request.post(Routes.DELETETDS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCashflowDetail = async (id) => {
  try {
    const result = await Request.get(Routes.GETCASHFLOWDETAILS + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getCommissionDetail = async (id) => {
  try {
    const result = await Request.get(Routes.GETCOMMISSIONDETAILS + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getCashflowDetailPdf = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCASHFLOWDETAILSPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-cashflowdetails-pdf/" + ino, {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // saveAs(pdfBlob, "dispatchreports_" + ino + ".pdf");

        saveAs(pdfBlob, `cashflow_${ino}.pdf`);
        // const fileurl = URL.createObjectURL(pdfBlob);
        // const pdfWindow = window.open();
        // pdfWindow.location.href = fileurl;
        // window.open(pdfBlob, "_blank");
      });
    });
  } catch (error) {
    throw error;
  }
};

const getCommissionDetailPdf = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETCOMMMISSIONDETAILSPDF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      let ino = data?.data?.ino;
      Request.get("/fetch-commissiondetails-pdf/" + ino, {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // saveAs(pdfBlob, "dispatchreports_" + ino + ".pdf");

        saveAs(pdfBlob, `commission_${ino}.pdf`);
        // const fileurl = URL.createObjectURL(pdfBlob);
        // const pdfWindow = window.open();
        // pdfWindow.location.href = fileurl;
        // window.open(pdfBlob, "_blank");
      });
    });
  } catch (error) {
    throw error;
  }
};

const getLeaveData = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETLEAVEDATA, formdata);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const insertLeave = async (fndata) => {
  try {
    const res = await Request.post(Routes.INSERTLEAVE, fndata);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateLeave = async (fndata) => {
  try {
    const res = await Request.post(Routes.UPDATELEAVE, fndata);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateLeaveStatus = async (fndata) => {
  try {
    const res = await Request.post(Routes.UPDATELEAVESTATUS, fndata);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteProforma = async (l_id, u_id) => {
  try {
    const res = await Request.get(
      Routes.DELETEPROFORMA + "/" + l_id + "/" + u_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getLeadCustomerbyid = async (id) => {
  try {
    const res = await Request.get(Routes.GETLEADCUSTOMERBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateLeadCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATELEADCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateProformaCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEPROFORMACUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
const getAttributeNameById = async (id) => {
  try {
    const res = await Request.get(Routes.GETATTRIBUTENAMEBYID + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const pickupSentBySelf = async (formdata) => {
  try {
    const res = await Request.post(Routes.PICKUPSENTBYSELF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getAllPurchaseList = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETALLPURCHASELIST, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const downloadPurchaseReport = async (formdata, type) => {
  try {
    await Request.post(Routes.DOWNLOADPURCHASEREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      Request.get("/fetch-purchase", {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        if (type == 0) {
          saveAs(pdfBlob, "purchaseReport.pdf");
        } else {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
        // saveAs(pdfBlob, "purchasereport.pdf");
      });
      return data;
    });
  } catch (error) {
    throw error;
  }
};

export const getPurchaseReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETPURCHASEREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getPurchaseTdsReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETPURCHASETDSREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getAllPurchaseTdsList = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETALLPURCHASETDSLIST, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const downloadPurchaseTDSReport = async (formdata, type) => {
  try {
    await Request.post(Routes.DOWNLOADPURCHASETDSREPORT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }).then((data) => {
      Request.get("/fetch-purchasetds", {
        responseType: "blob",
      }).then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        if (type == 0) {
          saveAs(pdfBlob, "purchaseTdsReport.pdf");
        } else {
          const fileurl = URL.createObjectURL(pdfBlob);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileurl;
          // window.open(pdfBlob, "_blank");
        }
        // saveAs(pdfBlob, "purchasereport.pdf");
      });
      return data;
    });
  } catch (error) {
    throw error;
  }
};

export const deletePurchaseTds = async (purchase_id, p_t_id) => {
  try {
    const res = await Request.get(
      Routes.DELETEPURCHASETDS + "/" + purchase_id + "/" + p_t_id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerByBranchId = async (branch_id, solo_user) => {
  try {
    const res = await Request.get(
      Routes.GETCUSTOMERBYBRANCH + "/" + branch_id + "/" + solo_user,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const insertPurchase = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTPURCHASE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePurchase = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEPURCHASE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePurchaseTDS = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEPURCHASETDS, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePurchaseStatus = async (data) => {
  try {
    const res = await Request.post(Routes.UPDATEPURCHASESTATUS, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getPurchasePayment = async (id) => {
  try {
    const res = await Request.get(Routes.GETPURCHASEPAYMENT + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const addPurchasePayment = async (formdata) => {
  try {
    const res = await Request.post(Routes.ADDPURCHASEPAYMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePurchasePayment = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDTAEPURCHASEPAYMENT, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getPurchasePaymentList = async (id) => {
  try {
    const res = await Request.get(Routes.GETPURCHASEPAYMENTLIST + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getAllExtraCourierList = async (formdata) => {
  try {
    const res = await Request.post(Routes.GETALLEXTRACOURIERLIST, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const addExtraCourier = async (formdata) => {
  try {
    const res = await Request.post(Routes.ADDEXTRACOURIER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updateExtraCourier = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEEXTRACOURIER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteExtraCourier = async (id) => {
  try {
    const res = await Request.get(Routes.DELETEEXTRACOURIER + "/" + id, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const sendLetterCourierMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDLETTERCOURIERMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getCoverLetterCode = async (formData) => {
  try {
    const res = await Request.post(Routes.GETCOVERLETTERCODE, formData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getAllWhatsAppTemplatesList = async (formdata) => {
  try {
    const res = await Request.get(Routes.GETALLWHATSAPPTEMPLATES, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const insertWhatsAppTemplate = async (formdata) => {
  try {
    const res = await Request.post(Routes.INSERTEHATSAPPTEMPLATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updateWhatsAppTemplate = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEWHATSAPPTEMPLATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const sendLeadWhatsAppMessage = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDLEADWHATSAPPMESSAGE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const sendCustomerWhatsAppMessage = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.SENDCUSTOMERWHATSAPPMESSAGE,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updateWpApiKey = async (fndata) => {
  try {
    const res = await Request.post(Routes.UPDATEWPAPIKEY, fndata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const wapMonkeyLink = async (formdata) => {
  try {
    const res = await RequestWp.post(Routes.WAPMONKEYLINK, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const wapMonkeyGetUserDevice = async (key) => {
  try {
    const res = await RequestWp.post(Routes.WAPGETUSERDEVICE, {
      Authorization: key,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const wapMonkeyRemainingCredit = async (key) => {
  try {
    const res = await RequestWp.post(Routes.WAPREMAININGCREDITAPI, {
      Authorization: key,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const sendPickUpOutMail = async (data) => {
  try {
    const res = await Request.post(Routes.SENDPICKUPOUTMAIL, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const sendBulkMail = async (formData) => {
  try {
    const res = await Request.post(Routes.SENDBULKMAIL, formData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const sendBulkMessage = async (formData) => {
  try {
    const res = await Request.post(Routes.SENDBULKMESSAGE, formData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const sendNoService = async (data) => {
  try {
    const res = await Request.post(Routes.SENDNOSERVICEMAIL, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getCommissionDropdown = async (data) => {
  try {
    const res = await Request.post(Routes.GETCOMMISSIONDROPDOWN, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data.data;
  } catch (error) {
    throw error;
  }
};

export const getLeaveNotification = async () => {
  try {
    const res = await Request.get(Routes.GETLEAVENOTIFICATION, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getPickupFromCityOffice = async (data) => {
  try {
    const res = await Request.post(Routes.GETPICKUPFROMCITYOFFICE, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getPickupFromCityOfficeReport = async (data) => {
  try {
    const res = await Request.post(Routes.GETPICKUPFROMCITYOFFICEREPORT, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const insertPickupFromCityOffice = async (data) => {
  try {
    const res = await Request.post(Routes.INSERTPICKUPFROMCITYOFFICE, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePickupFromCityOffice = async (data) => {
  try {
    const res = await Request.post(Routes.UPDATEPICKUPFROMCITYOFFICE, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const deletePickupFromCityOffice = async (id) => {
  try {
    const res = await Request.get(
      Routes.DELETEPICKUPFROMCITYOFFICE + "/" + id,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const readPickupDoneNotification = async (data) => {
  try {
    const res = await Request.post(Routes.READPICKUPDONENOTIFICATION, data, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const pickupSendingBySelf = async (formdata) => {
  try {
    const res = await Request.post(Routes.PICKUPSENDINGBYSELF, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const pickupDoneByCustomer = async (formdata) => {
  try {
    const res = await Request.post(Routes.PICKUPDONEBYCUSTOMER, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updateProcessDate = async (formdata) => {
  try {
    const res = await Request.post(Routes.UPDATEPROCESSDATE, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//dispatch
const searchReadyForDispatchForScan = async (formdata) => {
  try {
    const res = await Request.post(
      Routes.SEARCHREADYFORDISPATCHFORSCAN,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

//dispatch
const generatePickupExcelReport = async (formdata) => {
  try {
    const res = await Request.post(Routes.GENERATEPICKUPEXCELREPORT, formdata, {
      responseType: "arraybuffer",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

//Send Lead Mail
const sendScanDocumentMail = async (formdata) => {
  try {
    const res = await Request.post(Routes.SENDSCANDOCUMENTMAIL, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const changeIpCheck = async (is_ip) => {
  try {
    const res = await Request.get(Routes.ChangeIPCHECK + "/" + is_ip);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getLeadScheduledMailData = async (lead_id) => {
  try {
    const res = await Request.get(Routes.GETSCHEDULEDMAILDATA + "/" + lead_id);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deleteScheduleMailData = async (s_m_id) => {
  try {
    const res = await Request.get(Routes.DELETESCHEDULEMAILDATA + "/" + s_m_id);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const sendTestMailFunction = async (data) => {
  try {
    const res = await Request.post(Routes.SENDTESTMAIL, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateBillingSetting = async (data) => {
  try {
    const res = await Request.post(Routes.UPDATEBILLINGDETAILS, data);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getSupportingDocByCountry = async (country, type, certi) => {
  try {
    const res = await Request.get(
      Routes.GEYSUPPORTINGDOCBYCOUNTRY +
        "/" +
        country +
        "/" +
        type +
        "/" +
        certi
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getEmbassyByCountry = async (country, s_type, doc_type) => {
  try {
    const res = await Request.get(
      Routes.GETEMBASSYBYCOUNTRY +
        "/" +
        country +
        "/" +
        s_type +
        "/" +
        doc_type
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getLeadTemplateForBulkMail = async (type) => {
  try {
    const res = await Request.get(Routes.GETLEADTEMPLATEFORBULKMAIL + "/" + type);
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getCourierChargeByCountry = async (country, s_type) => {
  try {
    const res = await Request.get(
      Routes.GETCOURIERBYCOUNTRY +
        "/" +
        country +
        "/" +
        s_type 
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export {
  OtpRequest,
  verificationOfOtp,
  setNewPassword,
  changePassword,
  updateUserData,
  insertAttribute,
  getAttributes,
  updateAttrStatus,
  updateAttribute,
  insertBranch,
  getBranch,
  updateBranch,
  deleteBranch,
  getTypeCountry,
  insertService,
  getServicePrice,
  deleteService,
  insertFeature,
  getFeature,
  updateFeature,
  deleteFeature,
  getLeadSource,
  insertLead,
  getAllLead,
  deleteLead,
  updateLead,
  getAllBranch,
  getUsers,
  getLeadStatus,
  updateLeadBranch,
  updateLeadStatus,
  updateLeadPriority,
  insertLeadFollowUp,
  getFollowUp,
  updateSetting,
  getSetting,
  insertsmstemplate,
  getAllSMSTemlate,
  deleteSMSTemlate,
  updateSMSTemplate,
  updateTemplateStatus,
  updateAllocated,
  //jil sir
  getTemplateCategory,
  getTemplatesubCategory,
  insertemailtemplate,
  getEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
  // Sakshi
  insertRole,
  updateRole,
  getAllRoleList,
  getRolePermission,
  insertUser,
  getuserbyparentuser,
  getUserPermission,
  updateUser,
  deleteUser,
  getusersBytype,
  insertManagerAllocation,
  getmanagerallocationlist,
  getbranchallocatedlist,
  updateManagerAllocation,
  deleteManagerAllocation,
  deleteBranchallocatebyid,
  getBranchData,
  updateBranchSetting,
  getsearchLeadData,
  deleteEmailImg,
  fetchInvoiceDatabycid,
  updateCustomerAddress,
  SendInvoiceMail,
  getbranchdatabyid,
  fetchgraphleaddata,
  downloadallpdfreport,
  getSelectionwiseAmt,
  fetchMangerwiseBranch,
  fetchUserByBranch,
  getrevenuedata,
  gettemplatelistbycate,
  gettemplatedata,
  getcourierchargereport,
  fetchtotalinvoice,
  sendMailfortranslation,
  fetchcourierreport,
  searchAllcover,
  allcoverPDF,
  searchStatuswiseLeadReport,
  getAllProcessOverdue,
  setnextprocessfinishdate,
  getFAQcatepermission,
  getallpickupusers,
  //Task
  insertTask,
  getAllTask,
  deleteTask,
  deleteTaskImg,
  updateTask,
  updateTaskPriority,
  updateTaskStatus,
  insertTaskFeedback,
  getFeedback,
  getsearchTaskData,
  updatetaskAllocated,
  insertConfirmation,
  gettodayleadfollowup,
  getcountAllTask,
  getcountAllLead,
  getcountAllTodayLead,
  checkCustomer,
  insertCustomer,
  getCustomerList,
  getUser,
  getAttributesbyid,
  getCustomerAmount,
  //Visa
  getAllVisaList,
  //change
  getVisabyid,
  updateVisaStatus,
  deleteVisa,
  updateVisa,
  getsearchVisaData,
  //Attestation
  getAllAttestationList,
  updateattestationstatus,
  deleteAttestation,
  updateAttestation,
  getsearchAttestationData,
  //apostille
  getAllApostilleList,
  updateapostillestatus,
  deleteApostille,
  updateApostille,
  getsearchApostilleData,
  getApostillebyid,
  //Customer Comment
  insertCustomerConversation,
  getcomment,
  //Customer File
  uploadCustomerFile,
  getAllFiles,
  deleteFile,
  //customer-payment
  searchCustomerList,
  getPayment,
  updatePayment,
  getCustomerbyid,
  updateCustomer,
  getRefundAmt,
  deleteCustomer,
  //payment
  getPaymentList,
  getsearchPaymentData,
  //Refund
  insertrefund,
  getPaymentDetails,
  getAllRefunddata,
  getsearchRefundData,
  getsettingdata,
  //cashflow
  getBranchUser,
  getCashPayment,
  insertCashflow,
  getSearchCashFlow,
  deleteCashflow,
  updateApplicationStatus,
  getAllUnderProcesslist,
  //TDS
  getTDSList,
  updateTDS,
  getAttestationbyid,
  //coverletter
  getBusinessSummary,
  searchBusinessSummary,
  searchAttestationcover,
  attestationPDF,
  //apostillecover
  searchApostillecover,
  apostillePDF,
  //visacover
  searchVisacover,
  visaPDF,
  SendAttestationMail,
  SendApostilleMail,
  SendVisaMail,
  //dispatch
  searchDispatch,
  dispatchPDF,
  //excelcover
  searchExcelcover,
  //clientaccount
  getBranchCustomer,
  searchAccountcover,
  clientAccountPDF,
  //Refund-Paymentreceipt
  getRefundbyid,
  getRefundReceipt,
  getPaymentDatabyid,
  getPaymentReceipt,
  generateCRegistrationReport,
  generateRDeliveryReport,
  generateAllRDeliveryReport,
  generateRDeliveryHistoryReport,
  generateAllRDeliveryHistoryReport,
  //Send Mail in Refund
  SendRefundMail,
  //Send Mail in Payment
  SendPaymentMail,
  searchLeadReport,
  searchLeadCountReport,
  searchDirectCustomerReport,
  searchLeadAllocateReport,
  convertLead,
  getcountFollowUp,
  searchLeadFollowupReport,
  //fullcopy
  insertFullcopy,
  //halfcopy
  insertHalfcopy,
  //profitreport
  searchProfitReport,
  //Proforma Invoice
  insertLeadCustomer,
  fetchProformaInvoiceDatabylid,
  getProformaInvoice,
  updateService,
  updateDispatchDate,
  //Emailtemplatelist
  getAllEmailTemplate,
  //Send Lead Mail
  sendLeadMail,
  //branch transfer
  branchTransfer,
  ///////////////////////// customer /////////////////
  getCustomers,
  searchCustomers,
  getAttestations,
  searchAttestations,
  getApostilles,
  searchApostilles,
  //translationcust
  getTranslations,
  searchTranslations,
  getVisas,
  searchVisas,
  getPayments,
  searchPayments,
  getcustomerTDSList,
  searchTDSData,
  getApplicants,
  insertCustomerFeedback,
  getCustomerFeedbacks,
  getallInvoice,
  //Goal
  insertgoal,
  getAllGoal,
  updateGoal,
  deleteGoal,
  //Display Goal and Task
  getAllGoallist,
  getAllDashTask,
  //get task user
  getTaskUsers,
  getactivitylogbyid,
  getLoginActivityData,
  searchLoginActivityData,
  addlogouttime,
  getallholdapplication,
  insertFollowUp,
  //translation
  getAllTranslationList,
  getTranslationbyid,
  deleteTranslation,
  updateTranslation,
  getsearchTranslationData,
  translationPDF,
  searchTranslationcover,
  SendTranslationMail,
  blockCustomer,
  getnewTask,
  readTaskNotification,
  getTaskById,
  //Send mail with pdf and without pdf in lead proforma invoice
  SendLeadInvoiceMail,
  SendLeadEmail,
  //  Jil
  changeCustomerPassword,
  sendTempMail,
  getUserById,
  // FAQ
  getFaqCategory,
  insertFaqCategory,
  updateFaqCategory,
  insertFaq,
  getFaq,
  updateFAQ,
  deleteFaq,
  deleteFaqCategory,
  getProcessLimitDate,
  insertTarget,
  getTarget,
  updateTarget,
  getUserTaragetReport,
  //Draft Receipt
  approvedPayment,
  deletePayment,
  getDraftReceiptList,
  getDraftPaymentDatabyid,
  getDraftPaymentReceipt,
  getsearchDraftPaymentData,
  // account summary report
  searchAccountReport,
  //Suggestion
  insertsuggestion,
  getAllSuggestion,
  updateSuggestion,
  deleteSuggestion,
  getUserMonthTaragetReport,
  deleteTarget,
  //Main Customer
  getAllmaincustomer,
  updateMainCustomer,
  sendloginMail,
  getsearchMaincustomerData,
  getEmbassyFeeList,
  insertEmbassyFee,
  updateEmbassyFee,
  getEmbassyAmount,
  searchChargeReport,
  getallnewlead,
  generateCollectedReport,
  generateDispatchedReport,
  searchEmbassyFeesReport,
  getAllnewCustomerofAnotherBranch,
  getalldraftreceipt,
  getAllapplicants,
  fetchMonthInvoice,
  getAllInPickupList,
  insertPickupnote,
  getAllOutPickupList,
  pickupOutDone,
  getAllPickupDoneList,
  getAllPickupDoneLeadList,
  insertQuestion,
  getallquestion,
  deleteQuestion,
  updateQuestion,
  getAllQuestionlist,
  insertDailyQueAns,
  getuserchat,
  getallcustomerchat,
  insertusermessage,
  searchmaincustomerchat,
  fetchchatAllcustomer,
  getCustomerUser,
  gettemplatebody,
  getEmailHistory,
  getDraftReceiptCounter,
  getTaskReport,
  searchTDSReport,
  updateDraftPayment,
  updatePaymentMode,
  getservicevalue,
  insertDirCustomer,
  insertBankDetail,
  getBankDetails,
  deleteBankdetails,
  draftReceiptRead,
  branchCustomerRead,
  newLeadRead,
  getservicevalueType,
  newPickDoneRead,
  leadFollowRead,
  getsearchServiceprice,
  getreferralcustomerlist,
  getmaincustomerbyid,
  getCommisionData,
  updateCommisionPayment,
  getCommisionReportData,
  deleteCommision,
  checkcommissiondatabyid,
  getsearchEmbassyfees,
  getsearchFeedback,
  updatPaymentWithApproval,
  getAttestationListBycid,
  getSearchCashInHand,
  searchEmbassyFeesReportbyall,
  getleadfollowup,
  getUserList,
  getallchat,
  usercheck,
  gettodaycustomerfollowup,
  customerFollowRead,
  getcountCFollowUp,
  searchattribute,
  getApostilleListBycid,
  getTranslationListBycid,
  getVisaListBycid,
  updatunderprocessdate,
  updatePaymentWithAdditionalCharges,
  updatePaymentWithTdsAmount,
  SendPolicyMail,
  sendCodeMail,
  getDetailId,
  getAllCashFlow,
  readCashFlow,
  getAllDispatchingApplications,
  searchPickUpDoneCourierReport,
  getMultiInvoiceSingleCustomerReport,
  deleteTds,
  searchAllPickupDoneLeadList,
  getCashflowDetail,
  fetchAllcourierreport,
  getCashflowDetailPdf,
  getCommissionDetail,
  getCommissionDetailPdf,
  getLeaveData,
  insertLeave,
  updateLeave,
  updateLeaveStatus,
  deleteProforma,
  getLeadCustomerbyid,
  updateLeadCustomer,
  updateProformaCustomer,
  getAttributeNameById,
  pickupSentBySelf,
  updateProcessDate,
  getCourierChargeList,
  insertCourierCharge,
  updateCourierCharge,
  getsearchCourierCharges,
  getCourierChargeAmount,
  getAllSoloBranch,
  checkNetwork,
  searchReadyForDispatchForScan,
  sendScanDocumentMail,
  generatePickupExcelReport,
  changeIpCheck,
  getLeadScheduledMailData,
  deleteScheduleMailData,
};
