import React from "react";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() =>
  import("./views/theme/typography/Typography")
);
const Demos = React.lazy(() => import("./views/demo/Demo"));
// Base
const Accordion = React.lazy(() => import("./views/base/accordion/Accordion"));
const Breadcrumbs = React.lazy(() =>
  import("./views/base/breadcrumbs/Breadcrumbs")
);
const Cards = React.lazy(() => import("./views/base/cards/Cards"));
const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"));
const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"));
const ListGroups = React.lazy(() =>
  import("./views/base/list-groups/ListGroups")
);
const Navs = React.lazy(() => import("./views/base/navs/Navs"));
const Paginations = React.lazy(() =>
  import("./views/base/paginations/Paginations")
);
const Placeholders = React.lazy(() =>
  import("./views/base/placeholders/Placeholders")
);
const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"));
const Progress = React.lazy(() => import("./views/base/progress/Progress"));
const Spinners = React.lazy(() => import("./views/base/spinners/Spinners"));
const Tables = React.lazy(() => import("./views/base/tables/Tables"));
const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"));

// Buttons
const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"));
const ButtonGroups = React.lazy(() =>
  import("./views/buttons/button-groups/ButtonGroups")
);
const Dropdowns = React.lazy(() =>
  import("./views/buttons/dropdowns/Dropdowns")
);

//Forms
const ChecksRadios = React.lazy(() =>
  import("./views/forms/checks-radios/ChecksRadios")
);
const FloatingLabels = React.lazy(() =>
  import("./views/forms/floating-labels/FloatingLabels")
);
const FormControl = React.lazy(() =>
  import("./views/forms/form-control/FormControl")
);
const InputGroup = React.lazy(() =>
  import("./views/forms/input-group/InputGroup")
);
const Layout = React.lazy(() => import("./views/forms/layout/Layout"));
const Range = React.lazy(() => import("./views/forms/range/Range"));
const Select = React.lazy(() => import("./views/forms/select/Select"));
const Validation = React.lazy(() =>
  import("./views/forms/validation/Validation")
);

const Charts = React.lazy(() => import("./views/charts/Charts"));

// Icons
const CoreUIIcons = React.lazy(() =>
  import("./views/icons/coreui-icons/CoreUIIcons")
);
const Flags = React.lazy(() => import("./views/icons/flags/Flags"));
const Brands = React.lazy(() => import("./views/icons/brands/Brands"));

// Notifications
const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"));
const Badges = React.lazy(() => import("./views/notifications/badges/Badges"));
const Modals = React.lazy(() => import("./views/notifications/modals/Modals"));
const Toasts = React.lazy(() => import("./views/notifications/toasts/Toasts"));

const Widgets = React.lazy(() => import("./views/widgets/Widgets"));
const Demo = React.lazy(() => import("./views/demo/Demo"));

//Account
const Profile = React.lazy(() => import("./views/pages/Profile/profile"));

//Pages
const Attributes = React.lazy(() =>
  import("./views/pages/Attributes/atttributes")
);
const emailTemplate = React.lazy(() =>
  import("./views/pages/EmailTemplate/emailtemplate")
);

const WhatsAppTemplates = React.lazy(() =>
  import("./views/pages/WhatsAppTemplates/WhatsAppTemplates")
);

const BankDetails = React.lazy(() =>
  import("./views/pages/BankDetails/bankdetails")
);
// Setting
const Setting = React.lazy(() => import("./views/pages/Setting/setting"));

const Branch = React.lazy(() => import("./views/pages/Branch/branch"));
const Serviceprice = React.lazy(() =>
  import("./views/pages/ServicePrice/serviceprice")
);
const embassyFees = React.lazy(() =>
  import("./views/pages/EmbassyFees/Embassy_Fees")
);

const courierCharges = React.lazy(() =>
  import("./views/pages/CourierCharges/CourierCharges")
);

const Features = React.lazy(() => import("./views/pages/Features/features"));
const Lead = React.lazy(() => import("./views/pages/Lead/lead"));
const Pickupdone = React.lazy(() => import("./views/pages/Lead/pickupdone"));
const multiInvoiceReport = React.lazy(() =>
  import("./views/pages/CoverLetter/multiInvoiceReport")
);
const Smstemplate = React.lazy(() =>
  import("./views/pages/Smstemplate/smstemplate")
);
// Customer
const Customers = React.lazy(() => import("./views/pages/Customer/customers"));
const updateCustomer = React.lazy(() =>
  import("./views/pages/Customer/UpdateCustomer")
);
const updatePayments = React.lazy(() =>
  import("./views/pages/Customer/Payment")
);

//payment

const payments = React.lazy(() => import("./views/pages/Payment/payments"));
//cashflow
const cashflow = React.lazy(() => import("./views/pages/CashFlow/CashOutflow"));
//tds
const tds = React.lazy(() => import("./views/pages/TDS/tds"));

//Visa
const Visa = React.lazy(() => import("./views/pages/Visa/visa"));
//Attestation
const Attestation = React.lazy(() =>
  import("./views/pages/Attestation/attestation")
);
//Apostille
const Apostille = React.lazy(() => import("./views/pages/Apostille/apostille"));
//Translation
const Translation = React.lazy(() =>
  import("./views/pages/Translation/translation")
);
//Refund
const Refund = React.lazy(() => import("./views/pages/Refund/refund"));
//sakshi
//sakshi -invoice
const Invoiceview = React.lazy(() => import("./views/pages/Invoice/invoice"));
//Proforma Invoice
const Proformainvoiceview = React.lazy(() =>
  import("./views/pages/ProformaInvoice/proformainvoice")
);
//Mail send
const Mailsend = React.lazy(() => import("./views/pages/Mailsend/mailsend"));
//Mail history
const Tempmailhistory = React.lazy(() =>
  import("./views/pages/Mailsend/mailhistory")
);
//coverletter
const accountSummaryReport = React.lazy(() =>
  import("./views/pages/CoverLetter/accountSummary")
);
const businesssummary = React.lazy(() =>
  import("./views/pages/CoverLetter/businessSummary")
);
const loginactivitylog = React.lazy(() =>
  import("./views/pages/CoverLetter/loginActivityLog")
);
const attestationCover = React.lazy(() =>
  import("./views/pages/CoverLetter/attestationCover")
);
const visaCover = React.lazy(() =>
  import("./views/pages/CoverLetter/visaCover")
);
const readyforDelivery = React.lazy(() =>
  import("./views/pages/CoverLetter/readyforDelivery")
);
const dispatchAddress = React.lazy(() =>
  import("./views/pages/CoverLetter/dispatchAddress")
);
const scanDocument = React.lazy(() =>
  import("./views/pages/CoverLetter/scanDocument")
);
const pickupExcelReport = React.lazy(() =>
  import("./views/pages/CoverLetter/pickupExcelReport")
);
const excelReport = React.lazy(() =>
  import("./views/pages/CoverLetter/excelReport")
);
const registerReport = React.lazy(() =>
  import("./views/pages/CoverLetter/registerReport")
);

const PickUpDoneReport = React.lazy(() =>
  import("./views/pages/CoverLetter/pickupDoneReport")
);

const chargeReport = React.lazy(() =>
  import("./views/pages/CoverLetter/chargeReport")
);
const leadReport = React.lazy(() =>
  import("./views/pages/CoverLetter/leadReport")
);
const leaddfollowupcounterReport = React.lazy(() =>
  import("./views/pages/CoverLetter/leaddfollowupcounterReport")
);
//apostillecover
const apostilleCover = React.lazy(() =>
  import("./views/pages/CoverLetter/apostilleCover")
);
//Translationcover
const translationCover = React.lazy(() =>
  import("./views/pages/CoverLetter/translationCover")
);
//commission
const commissionreport = React.lazy(() =>
  import("./views/pages/CoverLetter/commissionReport")
);

//tdsreport
const tdsReport = React.lazy(() =>
  import("./views/pages/CoverLetter/tdsReport")
);
//clientaccount
const clientaccount = React.lazy(() =>
  import("./views/pages/CoverLetter/clientAccountReport")
);
//profitcost
const profitcostreport = React.lazy(() =>
  import("./views/pages/CoverLetter/ProfitReport")
);
//embassyFeesreport
const EmbassyFeesReport = React.lazy(() =>
  import("./views/pages/CoverLetter/EmbassyFeesReport")
);

const courierchargereport = React.lazy(() =>
  import("./views/pages/CoverLetter/courierchargereport")
);

const invoicereport = React.lazy(() =>
  import("./views/pages/CoverLetter/invoicereport")
);

//Task
const Task = React.lazy(() => import("./views/pages/Task/task"));

// Target
const target = React.lazy(() => import("./views/pages/Target/target"));

// Branch Setting
const Branchsetting = React.lazy(() =>
  import("./views/pages/Branchsetting/branchsetting")
);

const Role = React.lazy(() => import("./views/pages/Role/role"));
const User = React.lazy(() => import("./views/pages/User/user"));
const Manager_allocation = React.lazy(() =>
  import("./views/pages/Manager/m_branch_allocate")
);

//commision
const Comission = React.lazy(() => import("./views/pages/Comission/comission"));

// INQUIRY FORM
const InquiryForm = React.lazy(() =>
  import("./views/pages/InquiryForm/inquiryform")
);

const InquiryWebsiteLandscape1 = React.lazy(() =>
  import("./views/pages/InquiryForm/inquiryWebsiteLandscape1")
);
const InquiryWebsiteLandscape2 = React.lazy(() =>
  import("./views/pages/InquiryForm/inquiryWebsiteLandscape2")
);
const InquiryWebsitePortrait1 = React.lazy(() =>
  import("./views/pages/InquiryForm/inquiryWebsitePortrait1")
);
const InquiryWebsitePortrait2 = React.lazy(() =>
  import("./views/pages/InquiryForm/inquiryWebsitePortrait2")
);
const InquiryWebsitePortrait3 = React.lazy(() =>
  import("./views/pages/InquiryForm/inquiryWebsitePortrait3")
);


const ReferralForm = React.lazy(() =>
  import("./views/pages/ReferralForm/referralform")
);

//////////////////////////////// customer /////////////////////////////////////////
const myServiceList = React.lazy(() =>
  import("./views/pages/CustomerView/pages/MyAccount/myServiceList")
);
const myFeedback = React.lazy(() =>
  import("./views/pages/CustomerView/pages/MyAccount/myFeedbacks")
);
const addFeedback = React.lazy(() =>
  import("./views/pages/CustomerView/pages/MyAccount/addFeedback")
);
const attestationProcess = React.lazy(() =>
  import("./views/pages/CustomerView/pages/AttestationProcess/myApplicants")
);
const apostillepProcess = React.lazy(() =>
  import("./views/pages/CustomerView/pages/ApostilleProcess/myApplicants")
);
const translationProcess = React.lazy(() =>
  import("./views/pages/CustomerView/pages/TranslationProcess/myApplicants")
); //translationcust
const myPayment = React.lazy(() =>
  import("./views/pages/CustomerView/pages/MyPayments/myPayments")
);
const myTDS = React.lazy(() =>
  import("./views/pages/CustomerView/pages/MyPayments/myTDS")
);
const visaprocess = React.lazy(() =>
  import("./views/pages/CustomerView/pages/Visa/myApplicants")
);
const allprocess = React.lazy(() =>
  import("./views/pages/CustomerView/pages/Allapplication/myApplicants")
);
const changepassword = React.lazy(() =>
  import("./views/pages/CustomerView/pages/ChangePassword/changePassword")
);

const Faq = React.lazy(() => import("./views/pages/Faq/faq"));
const FaqCategory = React.lazy(() => import("./views/pages/Faq/faqCategory"));

const FaqDisplay = React.lazy(() =>
  import("./views/pages/FaqDisplay/faqdisplay")
);

const Goal = React.lazy(() => import("./views/pages/Goal/goal"));

const Draftreceipt = React.lazy(() =>
  import("./views/pages/Draftreceipt/draftreceipt")
);

const BankDraftreceipt = React.lazy(() =>
  import("./views/pages/Draftreceipt/bankdraftreceipt")
);

const Chat = React.lazy(() => import("./views/pages/Chat/chat"));

const Suggestion = React.lazy(() =>
  import("./views/pages/Suggestion/suggestion")
);

const Maincustomers = React.lazy(() =>
  import("./views/pages/Customer/maincustomers")
);

const Question = React.lazy(() => import("./views/pages/Question/question"));

const allcoverletter = React.lazy(() =>
  import("./views/pages/CoverLetter/allcoverletter")
);

const customerchat = React.lazy(() =>
  import("./views/pages/CustomerView/pages/Chat/chat")
);
const Referral = React.lazy(() =>
  import("./views/pages/CustomerView/pages/Referral/referral")
);
const ReferralCommision = React.lazy(() =>
  import("./views/pages/CustomerView/pages/ReferralCommision/referralcommision")
);

const mailhistory = React.lazy(() =>
  import("./views/pages/CustomerView/pages/MyAccount/mailhistory")
);

const TaskReport = React.lazy(() =>
  import("./views/pages/CoverLetter/taskreport")
);

const CustomerInvoiceview = React.lazy(() =>
  import("./views/pages/CustomerView/pages/cinvoice/cinvoice")
);

const Leave = React.lazy(() => import("./views/pages/Leave/Leave"));

const Purchase = React.lazy(() => import("./views/pages/Purchase/Purchase"));
const PurchaseTds = React.lazy(() =>
  import("./views/pages/Purchase/PurchaseTds")
);
const ExtraCouriers = React.lazy(() =>
  import("./views/pages/extraCourier/extraCourierList")
);
const CitiesOfficePickup = React.lazy(() =>
  import("./views/pages/CitiesOfficePickup/CitiesOfficePickup")
);

// const PurchaseReport = React.lazy(() =>
//   import("./views/pages/Purchase/PurchaseReport")
// );

// const AttendanceReport = React.lazy(() =>
//   import("./views/pages/AttendanceReport/AttendanceReport")
// );
const BulkMail = React.lazy(() => import("./views/pages/BulkMail/BulkMail"));
const BulkWhatsApp = React.lazy(() =>
  import("./views/pages/BulkWhatsapp/BulkWhatsApp")
);

const routes = [
  // { path: "*", exact: true,name: "Home"},

  { path: "/", exact: true, name: "Home", f_id: "All" },

  { path: "/inquiry-form", name: "Inquiry Form", component: InquiryForm },
  { path: "/inquiryform", name: "Inquiry Form", component: InquiryForm },
  { path: "/inquiry-landscape1", name: "Inquiry Form Landscape1", component: InquiryWebsiteLandscape1 },
  { path: "/inquirylandscape1", name: "Inquiry Form Landscape1", component: InquiryWebsiteLandscape1 },
  { path: "/inquiry-landscape2", name: "Inquiry Form Landscape2", component: InquiryWebsiteLandscape2 },
  { path: "/inquirylandscape2", name: "Inquiry Form Landscape2", component: InquiryWebsiteLandscape2 },
  { path: "/inquiry-portrait1", name: "Inquiry Form Portrait1", component: InquiryWebsitePortrait1 },
  { path: "/inquiryportrait1", name: "Inquiry Form Portrait1", component: InquiryWebsitePortrait1 },
  { path: "/inquiry-portrait2", name: "Inquiry Form Portrait2", component: InquiryWebsitePortrait2 },
  { path: "/inquiryportrait2", name: "Inquiry Form Portrait2", component: InquiryWebsitePortrait2 },
  { path: "/inquiry-portrait3", name: "Inquiry Form Portrait3", component: InquiryWebsitePortrait3 },
  { path: "/inquiryportrait3", name: "Inquiry Form Portrait3", component: InquiryWebsitePortrait3 },
  
  { path: "/referral-form", name: " Referral Form", component: ReferralForm },
  // { path: "/Demos", exact: true, name: "Demos" },

  { path: "/dashboard", name: "Dashboard", component: Dashboard, f_id: "All" },
  {
    path: "/add-faq",
    name: "FAQ",
    component: Faq,
    f_id: "sadmin",
  },
  {
    path: "/faq-category",
    name: "FAQ",
    component: FaqCategory,
    f_id: "sadmin",
  },
  {
    path: "/faq",
    name: "FAQ Display",
    component: FaqDisplay,
    f_id: "All",
  },
  { path: "/profile", name: "Profile", component: Profile, f_id: "All" },
  {
    path: "/attributes",
    name: "Attributes",
    component: Attributes,
    f_id: "sadmin",
  },
  { path: "/setting", name: "Setting", component: Setting, f_id: "sadmin" },
  { path: "/branch", name: "Branch", component: Branch, f_id: "sadmin" },
  {
    path: "/serviceprice",
    name: "ServicePrice",
    component: Serviceprice,
    f_id: 5,
  },
  { path: "/embassyFees", name: "Embassy Fees", component: embassyFees },
  {
    path: "/couriercharges",
    name: "Courier Charges",
    component: courierCharges,
  },
  {
    path: "/report/taskreport",
    name: "Task Report",
    component: TaskReport,
    f_id: 37,
  },
  {
    path: "/smstemplate",
    name: "Smstemplate",
    component: Smstemplate,
    f_id: 3,
  },
  {
    path: "/emailtemplate",
    name: "Email Template",
    component: emailTemplate,
    f_id: 4,
  },
  {
    path: "/whatsapptemplate",
    name: "WhatsApp Template",
    component: WhatsAppTemplates,
    f_id: 51,
  },
  {
    path: "/bankdetail",
    name: "Bank Details",
    component: BankDetails,
    f_id: 39,
  },
  // customer
  { path: "/customers", name: "Customers", component: Customers, f_id: 7 },
  { path: "/updateCustomer", name: "Customer", component: updateCustomer },
  { path: "/updatePayment", name: "UpdatePayment", component: updatePayments },
  //payment
  { path: "/payments", name: "Payments", component: payments, f_id: 12 },
  //cashflow
  { path: "/cashflow", name: "Cashflow", component: cashflow, f_id: 13 },
  //tds
  { path: "/tds", name: "tds", component: tds, f_id: 14 },
  // Branch Setting
  {
    path: "/branchsetting",
    name: "Branch Setting",
    component: Branchsetting,
    f_id: "bsetting",
  },
  {
    path: "/features",
    name: "Features",
    component: Features,
    f_id: "sadmin",
  },
  { path: "/lead", name: "Lead", component: Lead, f_id: 1 },
  {
    path: "/pickupdone",
    name: "Pick Up Done",
    component: Pickupdone,
    f_id: 34,
  },

  //sakshi
  { path: "/role", name: "Role", component: Role, f_id: "sadmin" },
  { path: "/user", name: "User", component: User, f_id: 2 },
  {
    path: "/mallocation",
    name: "Manager Allocation",
    component: Manager_allocation,
    f_id: "sadmin",
  },
  { path: "/invoiceview", name: "Invoice view", component: Invoiceview },

  //Task
  { path: "/task", name: "Task", component: Task, f_id: 6 },
  //Visa
  { path: "/visa", name: "Visa", component: Visa, f_id: 8 },
  //Attestation
  {
    path: "/attestation",
    name: "Attestation",
    component: Attestation,
    f_id: 9,
  },
  //Apostille
  { path: "/apostille", name: "Apostille", component: Apostille, f_id: 11 },
  //Translation
  {
    path: "/translation",
    name: "Translation",
    component: Translation,
    f_id: 16,
  },
  //Refund
  { path: "/refund", name: "Refund", component: Refund, f_id: 10 },
  //coverletter
  {
    path: "/coverletter",
    name: "Cover Letter",
    component: businesssummary,
    exact: true,
  },
  //report
  {
    path: "/report",
    name: "Report",
    component: businesssummary,
    exact: true,
  },
  {
    path: "/report/loginActivityLog",
    name: "Login-Logout Activity Report",
    component: loginactivitylog,
    f_id: "sadmin",
  },
  {
    path: "/report/businessSummary",
    name: "Business Summary Report",
    component: businesssummary,
    f_id: 19,
  },
  {
    path: "/coverletter/attestationCover",
    name: "Attestation Cover Letter",
    component: attestationCover,
    f_id: 20,
  },
  {
    path: "/coverletter/visaCover",
    name: "Visa Cover Letter",
    component: visaCover,
    f_id: 23,
  },
  {
    path: "/report/readyforDispatch",
    name: "Ready for Dispatch Report",
    component: readyforDelivery,
    f_id: 24,
  },
  {
    path: "/report/scanDocument",
    name: "Scan Document",
    component: scanDocument,
    // f_id: 57,
  },
  {
    path: "/report/pickupExcelReport",
    name: "Pickup Excel Report",
    component: pickupExcelReport,
    // f_id: 58,
  },
  {
    path: "/report/dispatchAddress",
    name: "Dispatch Address Report ",
    component: dispatchAddress,
    f_id: 26,
  },
  {
    path: "/report/excelReport",
    name: "Sales Summary Report",
    component: excelReport,
    f_id: 27,
  },
  {
    path: "/report/registerReport",
    name: "Client Registration Report",
    component: registerReport,
    f_id: 25,
  },
  {
    path: "/report/chargeReport",
    name: "Charge Wise Report",
    component: chargeReport,
    f_id: 28,
  },

  //comission
  {
    path: "/comission",
    name: "Comission",
    component: Comission,
    f_id: 40,
  },

  //apostillecover
  {
    path: "/coverletter/apostilleCover",
    name: "Apostille Cover Letter",
    component: apostilleCover,
    f_id: 21,
  },
  //Translatiioncover
  {
    path: "/coverletter/translationCover",
    name: "Translation Cover Letter",
    component: translationCover,
    f_id: 22,
  },
  {
    path: "/coverletter/allcoverletter",
    name: "All Cover Letter",
    component: allcoverletter,
  },
  //clientaccount
  {
    path: "/report/clientAccountReport",
    name: "Client Account Report",
    component: clientaccount,
    f_id: 29,
  },
  {
    path: "/report/leadReport",
    name: "Lead Report",
    component: leadReport,
    f_id: 30,
  },
  {
    path: "/report/leaddfollowupcounterReport",
    name: "Lead Follow Up Counter Report",
    component: leaddfollowupcounterReport,
    f_id: 31,
  },
  {
    path: "/report/accountSummaryReport",
    name: "Account Summary Report",
    component: accountSummaryReport,
    f_id: 36,
  },
  {
    path: "/report/invoicereport",
    name: "Invoice Report",
    component: invoicereport,
    f_id: 35,
  },
  //profitCost
  {
    path: "/report/profitCostReport",
    name: "Profit Report",
    component: profitcostreport,
    // f_id: 32,
  },
  {
    path: "/report/courierchargereport",
    name: "Courier Charge Report",
    component: courierchargereport,
    f_id: 33,
  },
  //embassyFeesreport
  {
    path: "/report/EmbassyFeesReport",
    name: "Embassy Fees Report",
    component: EmbassyFeesReport,
  },
  //tdsreport
  {
    path: "/report/tdsreport",
    name: "TDS Report",
    component: tdsReport,
  },
  {
    path: "/report/commissionreport",
    name: "Commission Report",
    component: commissionreport,
    f_id: 40,
  },
  {
    path: "/report/pickupdonecourier",
    name: "Pickup Done Courier",
    component: PickUpDoneReport,
    f_id: 47,
  },
  {
    path: "/report/multiInvoiceReport",
    name: "Multi Invoice single customer Report",
    component: multiInvoiceReport,
    f_id: 48,
  },
  {
    path: "/proformainvoiceview",
    name: "Proforma Invoice view",
    component: Proformainvoiceview,
  },

  {
    path: "/mailsend",
    name: "Mail Send",
    component: Mailsend,
  },

  {
    path: "/tempmailhistory",
    name: "Mail History",
    component: Tempmailhistory,
  },

  /////////////////TARGET/////////////////
  {
    path: "/target",
    name: "Target",
    component: target,
  },
  ///////////////////////////////// customer ////////////////////////////////////
  { path: "/myaccount", name: "My Service List", component: myServiceList },

  // { path: "/customer/myaccount", name: "My Service List", component: myServiceList},
  {
    path: "/myaccount/myService",
    name: "My Service List",
    component: myServiceList,
    exact: true,
  },
  {
    path: "/myaccount/myService",
    name: "My Service List",
    component: myServiceList,
    exact: true,
  },
  {
    path: "/myaccount/myFeedbacks",
    name: "My Feedbacks",
    component: myFeedback,
    exact: true,
  },
  {
    path: "/myaccount/addFeedbacks",
    name: "Add Feedback",
    component: addFeedback,
    exact: true,
  },
  {
    path: "/myaccount/mailhistory",
    name: "Mail History",
    component: mailhistory,
    exact: true,
  },

  {
    path: "/attestationProcess",
    name: "Attestation Process Status",
    component: attestationProcess,
  },
  {
    path: "/apostilleProcess",
    name: "Apostille Process Status",
    component: apostillepProcess,
  },
  {
    path: "/translationProcess",
    name: "Translation Process Status",
    component: translationProcess,
  }, //translationcust

  { path: "/mypayments", name: "My Payments", component: myPayment },
  { path: "/mypayments/myPayments", name: "My Payments", component: myPayment },
  { path: "/mypayments/myTDS", name: "My TDS", component: myTDS },

  { path: "/visaProcess", name: "Visa Process Status", component: visaprocess },
  { path: "/allProcess", name: "All Process Status", component: allprocess },
  { path: "/customerchat", name: "Customer Chat", component: customerchat },
  { path: "/referral", name: "referral", component: Referral },
  {
    path: "/referralcommision",
    name: "referralcommision",
    component: ReferralCommision,
  },
  {
    path: "/changepassword",
    name: "Change Password",
    component: changepassword,
  },
  { path: "/customer-panel", exact: true, name: "Home" },
  {
    path: "/cinvoiceview",
    name: "Invoice view",
    component: CustomerInvoiceview,
  },
  { path: "/goal", name: "Goal", component: Goal, f_id: 15 },
  { path: "/draftreceipt", name: "Draft Receipt", component: Draftreceipt },
  {
    path: "/bankdraftreceipt",
    name: "Draft Receipt",
    component: BankDraftreceipt,
  },
  { path: "/suggestion", name: "Suggestion", component: Suggestion },
  { path: "/maincustomers", name: "Main customers", component: Maincustomers },
  { path: "/question", name: "Question", component: Question, f_id: 38 },
  { path: "/chat", name: "Chat", component: Chat, f_id: "All" },
  { path: "/leave", name: "Leave", component: Leave, f_id: "All" },
  {
    path: "/scanDocument",
    name: "Scan Document",
    component: scanDocument,
    // f_id: "All",
    f_id: 57,
  },
  {
    path: "/pickupExcelReport",
    name: "Pickup Excel Report",
    component: pickupExcelReport,
    // f_id: "All",
    f_id: 58,
  },
  { path: "/purchase", name: "Purchase", component: Purchase, f_id: 49 },
  {
    path: "/purchasetds",
    name: "Purchase TDS",
    component: PurchaseTds,
    f_id: 49,
  },
  {
    path: "/extracourier",
    name: "Additional Courier",
    component: ExtraCouriers,
    f_id: 50,
  },
  {
    path: "/citiesofficepickup",
    name: "Extra Pickup",
    component: CitiesOfficePickup,
    f_id: 55,
  },
  // {
  //   path: "/purchasereport",
  //   name: "Purchase Report",
  //   component: PurchaseReport,
  //   f_id: 49,
  // },

  // {
  //   path: "/attendancereport",
  //   name: "Attendance Report",
  //   component: AttendanceReport,
  // },
  { path: "/bulkMail", name: "Bulk Mail", component: BulkMail, f_id: 53 },
  {
    path: "/bulkWhatsApp",
    name: "Bulk WhatsApp",
    component: BulkWhatsApp,
    f_id: 54,
  },
];

export default routes;
